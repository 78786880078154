exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-archive-tsx": () => import("./../../../src/pages/archive.tsx" /* webpackChunkName: "component---src-pages-archive-tsx" */),
  "component---src-pages-facility-tsx": () => import("./../../../src/pages/facility.tsx" /* webpackChunkName: "component---src-pages-facility-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-media-tsx": () => import("./../../../src/pages/media.tsx" /* webpackChunkName: "component---src-pages-media-tsx" */),
  "component---src-pages-milk-tsx": () => import("./../../../src/pages/milk.tsx" /* webpackChunkName: "component---src-pages-milk-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-templates-archive-tsx": () => import("./../../../src/templates/archive.tsx" /* webpackChunkName: "component---src-templates-archive-tsx" */),
  "component---src-templates-media-tsx": () => import("./../../../src/templates/media.tsx" /* webpackChunkName: "component---src-templates-media-tsx" */)
}

