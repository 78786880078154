/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
const { renderAA } = require('./src/util/aa');

exports.onInitialClientRender = () => {
  if (process.env.NODE_ENV === 'production') renderAA();
};
